<template>
    <div class="order-to-contractor-diff-report" v-if="report">
        <b-card style="margin-top:10px;" class="table-responsive">
            <router-link
                class="btn btn-info pull-right"
                style="color:#fff;margin-bottom:20px;"
                :to="{ name: 'OrderToContractorActForm', params: { id: this.id } }"
            >Заказ поставщику</router-link>

            <b-dropdown
                v-if="report.admissionActIds && report.admissionActIds.length"
                variant="danger"
                class="pull-right"
                style="color:#fff;margin:0 10px 0 20px;"
                text="Приемка сырья"
            >
                <b-dropdown-item v-for="actId in report.admissionActIds" :key="actId">
                    <router-link :to="{ name: 'AdmissionActEdit', params: { id: actId } }"
                    >Акт приемки №{{ actId }}</router-link>
                </b-dropdown-item>
            </b-dropdown>


            <b-table stipred :responsive="true" :items="preparedItems" :fields="fields"></b-table>
        </b-card>
    </div>
</template>

<script>
    import forEach from 'lodash/forEach';
    import { ORDER_TO_CONTRACTOR_ACTS_ENDPOINT } from "@utils/endpoints";

    export default {
        name: "OrderToContractorActDiffReport",
        props: {
            id: {}
        },
        data() {
            return {
                report: null,
                fields: [
                    { key: 'nomenclature_name', label: 'Номенклатура' },
                    { key: 'quantity_ordered', label: 'Кол-во заказано' },
                    { key: 'quantity_accepted', label: 'Кол-во принято' },
                    { key: 'difference_quantity', label: 'Расхождения' },
                ],
            };
        },
        computed: {
            preparedItems() {
                let preparedItems = [];

                forEach( this.report.diffItems, function ( record ) {
                    let measure = record.measure;

                    preparedItems.push( {
                        nomenclature_name: record.nomenclature_name,
                        quantity_ordered: parseFloat( record.quantity_ordered ).toFixed( 3 ) + ' ' + measure,
                        quantity_accepted: parseFloat( record.quantity_accepted ).toFixed( 3 ) + ' ' + measure,
                        difference_quantity: parseFloat( record.difference_quantity ).toFixed( 3 ) + ' ' + measure
                    } );
                } );

                return preparedItems;
            },
            preparedParams() {
                return {
                    with: [
                        'contractor',
                        'orderToContractorItems.nomenclature.measureUnit',
                        'admissionActs',
                        'admissionAct',
                    ],
                    appends: [
                        'status',
                    ],
                }
            },
        },
        methods: {
            getDiffReport() {
                this.$http
                    .get( ORDER_TO_CONTRACTOR_ACTS_ENDPOINT + '/diff-report/' + this.id, {params: this.preparedParams} )
                    .then( response => {
                        if ( response.data.status ) {
                            this.report = response.data.report;
                        }
                    } );
            }
        },
        mounted() {
            this.getDiffReport();
        }
    }
</script>

<style scoped lang="sass">

</style>
